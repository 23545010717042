import React, { Component } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../../../layout"

class GamePage extends Component {
  render() {
    return (
      <Layout>
        <h1>web games</h1>
        <p>made for fun</p>
        <hr/>
        <Link to='/code/games/gobblet' className='box has-text-right'>
          <span className='is-size-4'>gobblet &rarr;</span>
          <p>kind of like tic tac toe, but with Russian dolls</p>
        </Link>
      </Layout>
    )
  }
}

export default GamePage
